<template>
  <div class="readme-article">
    <h1 id="聊天侧边栏">六、聊天侧边栏</h1>
    <h2 id="用户画像">1.用户画像</h2>
    <p>
      什么是用户画像？
    </p>
    <p>
      <img src="@/assets/img/scrm/6-1.png" />
    </p>
    <h2 id="聊天增强">2.聊天增强</h2>
    <p>
      什么是聊天增强？
    </p>
    <p>
      <img src="@/assets/img/scrm/6-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm6-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>